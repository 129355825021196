// import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const breakpoints = {
    sm: '(min-width: 576px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1200px)',
};

export const Img = styled(GatsbyImage)`
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1rem;
`;

export const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
`;

export const Button = styled.button`
  appearance: none;
  display: inline;
  cursor: pointer;
  padding: 0;
  background: none;
  border: none;
  font-family: inherit;
  font-size: inherit;
  outline: none;
  text-transform: uppercase;
  padding: 0;
  margin: 0;

  &:hover {
    color: rgba(0, 0, 0, 0.3);
  }

  &:disabled {
    cursor: default;
  }

  &.desktop {
    display: none;
  }

  &.mobile {
    display: inline;
  }

  @media ${breakpoints.lg} { 
    &.desktop {
      display: inline;
    }

    &.mobile {
      display: none;
    }
  }
`;

export const CheckoutButton = styled.button`
  appearance: none;
  display: block;
  cursor: pointer;
  padding: 0;
  background: none;
  border: none;
  font-family: inherit;
  font-size: inherit;
  outline: none;
  text-transform: uppercase;

  color: rgba(0, 0, 0, 1);
  padding: 23px 20px 20px;
  border: 2px solid rgba(0, 0, 0, 1);
  line-height: 1;
  width: 100%;
  margin-top: 25px;

  &:hover {
    color: rgba(0, 0, 0, 1);
    border-color: rgba(0, 0, 0, 1);
  }
`;

export const Section = styled.section`
  padding: 100px 27px 200px;
  margin: 0 auto;
  max-width: 1800px;

  & + & {
    padding-top: 50px;
  }

  @media ${breakpoints.lg} { 
    padding: 100px 50px 200px;

    & + & {
      padding-top: 50px;
    }
  }
`;

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2rem 1fr;
  grid-template-rows: 1auto;
  grid-template-areas: "left . right";

  @media ${breakpoints.md} { 
    display: block;
  }
`;

export const GridLeft = styled.div`
  grid-area: left;
`;

export const GridRight = styled.div`
  grid-area: right;
`;

export const MainContent = styled.main`
  margin-top: 80px;
  margin-bottom: 40px;

  @media ${breakpoints.md} { 
    margin-top: 40px;
    margin-bottom: 20px;
  }
`;

export const ProductSection = styled.section`
  margin-top: 27px;
  padding: 0 27px;

  @media ${breakpoints.lg} {
    margin-top: 60px;
    padding: 0 50px;
  }
`;

export const Product = styled.div`
  //min-height: 100%;
  // text-transform: uppercase;
  text-align: center;
  display: block;
  margin: 0 auto;

  @media ${breakpoints.lg} { 
    // width: 50%;
  }
`;

export const ProductTitle = styled.h1`
  font-size: inherit;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;
  user-select: none;
  margin-top: 1rem;
`;

export const ProductSubtitle = styled.h3`
  font-size: inherit;
  font-weight: normal;
  margin: 0 0 0.1rem;
`;

export const TextIndent = styled.div`
  position: relative;
  margin: auto;
  max-width: 500px;
  padding-left: 22px;
  box-sizing: border-box;

  &:before {
    width: 2px;
    height: 100%;
    height: calc(100% - 6px);
    background: currentColor;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
  }
`;

export const ProductDescription = styled.div`
  margin: 70px auto 40px;
  max-width: 500px;

  text-align: left;
  //font-family: "Helvetica","Helvetica",sans-serif;
  //font-weight: 300;

  ${TextIndent} > & {
    margin-bottom: -4px;
  }
`;

export const PageHeader = styled.header`
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 0px;
    text-align: center;

    @media ${breakpoints.md} {
        margin-bottom: 15px;
    }
`;

export const SingleSection = styled(Section)`
    padding-top: 0;

    @media ${breakpoints.md} {
        padding-top: 0;
    }
`;

export const LargeTextStyle = css`
  font-family: 'BrancherBeta-Regular';
  letter-spacing: 0.095em;
  line-height: 0.85;
  margin: 0;
  font-size: 38px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;

  @media ${breakpoints.lg} { 
    font-size: 60px;
  }
`;

export const PageHeadline = styled.h1`
    ${LargeTextStyle}

    line-height: 70px;
    margin: 65px 0 27px;

    @media ${breakpoints.lg} {
        line-height: 110px;
        margin: ${Math.round((110 - 51) / 2)}px 0 27px;
    }
`;

export const CommonMenuStyle = css`
    font-size: 1rem;
    text-decoration: none;
    text-transform: uppercase;
    align-self: flex-start;
`;
